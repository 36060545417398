/*
 * @Description: 弹窗添加编辑的mixin
 */

import DEFAULT_FORM_LAYOUT from '@/config/formLayout'

const modalFormMixin = {
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      formLayout: DEFAULT_FORM_LAYOUT,
      ApiController: {},
      item: {},
      // form: {},
      // rules: {}
    }
  },
  computed: {
    isEdit() {
      return this.item.id
    },
    title() {
      return this.isEdit ? '编辑' : '新增'
    }
  },
  methods: {
    // // 显示事件
    async handleShow(item) {
      // this.show = true
      // this.item = { ...item }
      // this.form = { ...FORM(), ...item }
      // this.$nextTick(() => {
      //   this.$refs.form.clearValidate()
      // })
    },
    getSubmitForm() {
      return { ...this.form }
    },
    // 提交表单
    async handleConfirm() {
      const params = this.getSubmitForm()

      try {
        this.loading = true
        await this.$refs.form.validate()
        if (this.isEdit) {
          await this.ApiController.edit(params)
        } else {
          await this.ApiController.add(params)
        }
        this.$message.success(`${this.isEdit ? '编辑' : '新增'}成功!`)
        this.$emit('successed')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  }
}

export default modalFormMixin
