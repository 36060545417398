var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c("a-card", { staticClass: "report_data_manage_wrapper" }, [
        _c("div", { staticClass: "main_content" }, [
          _c("div", { staticClass: "left_wrapper" }, [_c("TreeArea")], 1),
          _c(
            "div",
            { staticClass: "right_wrapper" },
            [
              _c(_vm.currentListComp, {
                tag: "component",
                staticClass: "component_wrapper",
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }