<!--
 * @descriptionription: 编辑
-->
<template>
  <a-modal
    v-model="show"
    class="form_wrapper"
    width="700px"
    :title="title"
    @ok="handleConfirm"
    :confirmLoading="loading"
  >
    <a-form-model :model="form" :rules="rules" ref="form" v-bind="formLayout">
      <a-form-model-item prop="type" label="测线类型">
        <EnumSelector v-model="form.type" :enumConfig="LINE_TYPE_ENUM" placeholder="请选择测线类型"></EnumSelector>
      </a-form-model-item>

      <a-form-model-item prop="number" label="测线序号">
        <a-input
          type="number"
          placeholder="请输入测线序号"
          v-model="form.number"
          style="width: 100%;"
          :min="1"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item prop="distance" label="距离墙头（米）">
        <PriceInput v-model="form.distance" placeholder="请输入距离墙头距离"></PriceInput>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import ApiController from '@/views/report/api/line'
import modalFormMixin from '@/mixins/modalFormMixin'
import { LINE_TYPE_ENUM } from '@/views/report/_const'
import { priceValRule as NumberInputRule } from '@/utils/rules'

const FORM = () => ({
  number: undefined,
  distance: undefined,
  type: undefined
})

export default {
  mixins: [modalFormMixin],
  data() {
    const validateMax = (rule, value, callback) => {
      if (isNaN(Number(value))) {
        callback(new Error('请输入合法序号'))
      } else if (Number(value) > 99999) {
        callback(new Error('超过最大序号99999'))
      } else {
        callback()
      }
    }

    return {
      LINE_TYPE_ENUM,
      ApiController,
      form: FORM(),
      rules: {
        type: [{ required: true, message: '请选择测线类型', trigger: ['blur', 'change'] }],
        number: [
          { required: true, message: '请输入测线序号', trigger: 'blur' },
          { pattern: /^\d*$/, message: '请输入正确的墙面序号', trigger: 'change' },
          { validator: validateMax, trigger: 'blur' }
        ],
        distance: NumberInputRule('距离墙头距离', 99999)
      }
    }
  },
  methods: {
    // 显示事件
    async handleShow(item) {
      this.show = true
      this.item = { ...item }
      this.form = { ...FORM(), ...item }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    getSubmitForm() {
      return {
        ...this.form,
        wallId: this.$store.state.report.currentNode.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
