<!--
 * @descriptionription: 编辑
-->
<template>
  <a-modal
    v-model="show"
    class="form_wrapper"
    width="700px"
    :title="title"
    @ok="handleConfirm"
    :confirmLoading="loading"
  >
    <a-form-model :model="form" :rules="rules" ref="form" v-bind="formLayout">
      <a-form-model-item prop="number" label="检测点序号">
        <a-input-number
          placeholder="请输入检测点序号"
          v-model="form.number"
          style="width: 100%;"
          :precision="0"
          :min="1"
        ></a-input-number>
      </a-form-model-item>
    </a-form-model>

    <div class="tip_wrapper" v-if="!isEdit">
      <span class="tip">{{ tip }}</span>
    </div>
  </a-modal>
</template>

<script>
import ApiController from '@/views/report/api/point'
import modalFormMixin from '@/mixins/modalFormMixin'

const FORM = () => ({
  number: undefined
})

export default {
  mixins: [modalFormMixin],
  props: {
    tip: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateMax = (rule, value, callback) => {
      if (isNaN(Number(value))) {
        callback(new Error('请输入合法序号'))
      } else if (Number(value) > 99999) {
        callback(new Error('超过最大序号99999'))
      } else {
        callback()
      }
    }

    return {
      ApiController,
      form: FORM(),
      rules: {
        number: [
          { required: true, message: '请输入检测点序号', trigger: 'blur' },
          { validator: validateMax, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 显示事件
    async handleShow(item) {
      this.show = true
      this.item = { ...item }
      this.form = { ...FORM(), ...item }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    getSubmitForm() {
      return {
        ...this.form,
        lineId: this.$store.state.report.currentNode.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tip_wrapper {
  text-align: right;
}
</style>
