/*
 * @Description: 上传数据列表mixin
 */

const DATA_TYPE_ENUM = {
  'WALL': '墙面',
  'KNOCK_LINE': '敲击检测',
  'POINT': '雷达检测'
}

const uploadDataMixin = {
  data() {
    return {
      ApiController: {},
      loading: false,
      tableData: []
    }
  },
  computed: {
    currentNode() {
      return this.$store.state.report.currentNode
    },
    couldEdit() {
      const couldEditStatusList = [ 'DETECTING', 'DETECTED', 'UPLOADED', 'AVAILABLE_REJECTED' ]
      return couldEditStatusList.includes(this.$route.query?.status)
    }
  },
  watch: {
    currentNode: {
      immediate: true,
      handler(newVal) {
        if (newVal?.id) {
          this.fetchData()
        }
      }
    }
  },
  methods: {
    // 拉取数据
    async fetchData() {
      const params = { id: this.currentNode.id }

      try {
        this.loading = true
        const data = await this.ApiController.getList({ ...params })
        data.typeName = DATA_TYPE_ENUM[this.currentNode.type] // 需要自行判断上传数据类型
        this.tableData = [data]
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}

export {
  uploadDataMixin,
}
