<!--
 * @Description: 列表
-->
<template>
  <a-card class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->
    <div class="mb-10 btn_group_wrapper">
      <a-button type="primary" v-if="couldEdit" @click="() => handleAddEditItem()">新增墙面</a-button>
    </div>

    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="墙面序号" field="number"></vxe-column>
        <vxe-column title="创建时间" field="createTime"></vxe-column>
        <vxe-column title="可见光拍照" field="visibleLight">
          <template #default="{ row }">
            <span>{{ row.visibleLight ? '需要' : '不需要' }}</span>
          </template>
        </vxe-column>
        <vxe-column title="热成像拍照" field="thermalImage">
          <template #default="{ row }">
            <span>{{ row.thermalImage ? '需要' : '不需要' }}</span>
          </template>
        </vxe-column>

        <vxe-column title="操作" field="operate" align="right" v-if="couldEdit">
          <template #default="{ row }">
            <a-button type="link" @click="() => handleAddEditItem(row)">修改</a-button>
            <a-button type="link" @click="() => handleDeleteItem(row)">删除</a-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <!-- 分页 -->
    <!-- <Pagination :pagination.sync="pagination" @change="fetchData" /> -->

    <AddEditItemDialog ref="AddEditItemDialog" @successed="updateTableAndTree"></AddEditItemDialog>
  </a-card>
</template>

<script>
import ApiController from '@/views/report/api/wall'
import AddEditItemDialog from './AddEditItemDialog'
import { TableMixin } from '@/views/report/_mixin'

export default {
  mixins: [TableMixin],
  components: {
    AddEditItemDialog
  },
  data() {
    return {
      ApiController
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
