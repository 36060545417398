var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "form_wrapper" },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "FilterLayout",
            { on: { reset: _vm.reset, search: _vm.search } },
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "number", label: "检测点序号" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入检测点序号" },
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "startAndEnd", label: "创建时间" } },
                [
                  _c("a-range-picker", {
                    model: {
                      value: _vm.form.startAndEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startAndEnd", $$v)
                      },
                      expression: "form.startAndEnd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }