<!--
 * @Description: 单文件上传简单封装
-->
<template>
  <a-upload
    name="file"
    withCredentials
    v-bind="$attrs"
    action="/foundation/api/file"
    :file-list="fileList"
    @change="handleChange"
  >
    <a-button type="primary">选择文件</a-button>
  </a-upload>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  watch: {
    // 如果需要做初始化，需要修改watch
    // 并且需要指定name
    value: {
      // immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.fileList = []
        }
      }
    }
  },
  methods: {
    handleChange(info) {
      let fileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1)

      // 2. read from response and show file link
      fileList = fileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
      const url = this.fileList[0]?.url
      this.$emit('input', url)
      this.$emit('change', url)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
