<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="name" label="楼栋名称">
          <a-input v-model="form.name" placeholder="请输入楼栋名称"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import { filterFormMixin } from '@/mixins/filterFormMixin'

const FORM = () => ({
  startAndEnd: [undefined, undefined],
  name: ''
})

export default {
  mixins: [filterFormMixin],
  data() {
    return {
      form: FORM()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
