/*
 * @Description:
 */
import { requestCustom as request } from '@/utils/request'

class ControllerFac {
  constructor(controllerURL) {
    this.controllerURL = controllerURL
    // 标准方法
    this.getList = this._getList
    this.addItem = this._addItem
    this.deleteItem = this._deleteItem
    this.editItem = this._editItem
    this.getItem = this._getItem

    // 以下是定义别名
    this.list = this._getList
    this.add = this._addItem
    this.delete = this._deleteItem
    this.edit = this._editItem
    this.get = this._getItem
  }

  // 获取列表数据
  _getList(params) {
    return request({
      url: `${this.controllerURL}`,
      method: 'get',
      params
    })
  }

  // 添加
  _addItem(params) {
    return request({
      url: `${this.controllerURL}`,
      method: 'post',
      data: params
    })
  }

  // 删除
  _deleteItem(params) {
    return request({
      url: `${this.controllerURL}/${params.id}`,
      method: 'delete'
    })
  }

  // 编辑
  _editItem(params) {
    return request({
      url: `${this.controllerURL}`,
      method: 'put',
      data: params
    })
  }

  // 获取(单个)详情
  _getItem(params) {
    return request({
      url: `${this.controllerURL}/${params.id}`,
      method: 'get'
    })
  }
}

export {
  ControllerFac
}

export default ControllerFac
