var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wall_list_wrapper" },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [_c("a-tab-pane", { key: "1", attrs: { tab: "墙面列表" } })],
        1
      ),
      _c("FilterForm", { ref: "FilterForm", on: { search: _vm.updateTable } }),
      _c("div", { staticClass: "mt-10" }),
      _c("MainTable", {
        ref: "MainTable",
        staticClass: "main_table",
        attrs: { getFilter: _vm.getFilter },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }