<!--
 * @Description: 墙面列表
-->
<template>
  <div class="line_list_wrapper">
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="测线列表">
        <div></div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据上传">
        <div></div>
      </a-tab-pane>
    </a-tabs>
    <template v-if="activeKey === '1'">
      <FilterForm ref="FilterForm" @search="updateTable"></FilterForm>
      <div class="mt-10"></div>
      <MainTable ref="MainTable" class="main_table" :getFilter="getFilter"></MainTable>
    </template>

    <template v-else>
      <UploadDataList></UploadDataList>
    </template>
  </div>
</template>
</template>

<script>
import FilterForm from './FilterForm.vue'
import MainTable from './MainTable.vue'
import UploadDataList from './UploadDataList.vue'

export default {
  name: '',
  components: {
    FilterForm,
    MainTable,
    UploadDataList
  },
  data() {
    return {
      activeKey: '1'
    }
  },
  methods: {
    getFilter() {
      return this.$refs.FilterForm.form
    },
    updateTable() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
