<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="number" label="检测点序号">
          <a-input v-model="form.number" placeholder="请输入检测点序号"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import filterFormMixin from '@/mixins/filterFormMixin.js'

const FORM = () => ({
  number: '',
  startAndEnd: [undefined, undefined]
})

export default {
  mixins: [filterFormMixin],
  data() {
    return {
      form: FORM()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
