/*
 * @Description: 文件描述
 */

import ControllerFac from '@/api/utils/ControllerFac'
import { requestCustom as request } from '@/utils/request'

class ReportControllerFac extends ControllerFac {
  // 获取列表数据
  _getList(params) {
    return request({
      url: `${this.controllerURL}/${params.id}`,
      method: 'get',
      params
    })
  }
}

export {
  ReportControllerFac
}
