var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-tree", {
    attrs: {
      "show-line": "",
      "expanded-keys": _vm.expandedKeys,
      "auto-expand-parent": _vm.autoExpandParent,
      "selected-keys": _vm.selectedKeys,
      "tree-data": _vm.treeData,
    },
    on: { expand: _vm.onExpand, select: _vm.onSelect },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function (ref) {
          var title = ref.title
          var red = ref.red
          return [
            _c("a-badge", { attrs: { dot: red } }, [
              _c("span", [_vm._v(_vm._s(title))]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }