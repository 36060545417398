<!--
 * @Description: 上传列表
-->
<template>
  <div class="table_wrapper">
    <vxe-table :data="realTableData" :loading="loading">
      <vxe-column title="数据类型" field="typeName"></vxe-column>
      <vxe-column title="上传时间" field="uplodaTime"></vxe-column>
      <vxe-column title="上传人" field="uplodaTime"></vxe-column>
      <vxe-column title="上传人联系电话" field="uplodaTime"></vxe-column>

      <vxe-column title="操作" field="operate" align="right" v-if="couldEdit">
        <template #default="{ row }">
          <a-button type="link" v-if="!row.hasData" @click="() => handleUpload(row)">上传数据</a-button>
          <a-button type="link" v-else @click="() => handleUpload(row)">重新上传</a-button>
        </template>
      </vxe-column>
    </vxe-table>

    <!-- 上传弹窗 -->
    <uploadDataDialog ref="uploadDataDialog" @success="handleUploadSucess"></uploadDataDialog>
  </div>
</template>

<script>
import uploadDataController from '@/views/report/api/uploadData'
import { uploadDataMixin } from '@/views/report/_mixin'
import uploadDataDialog from './uploadDataDialog.vue'
import { bus } from '@/views/report/_utils'

const ApiController = {
  getList: uploadDataController.wallUploadData
}

export default {
  mixins: [uploadDataMixin],
  components: {
    uploadDataDialog
  },
  data() {
    return {
      ApiController,
      loading: false,
      tableData: []
    }
  },
  computed: {
    realTableData() {
      const [data = {}] = this.tableData || []

      const {
        thermalImage,
        tiHasData,
        tiUploadPhone,
        tiUploadTime,
        tiUploadUsername,
        visibleLight,
        vlHasData,
        vlUploadPhone,
        vlUploadTime,
        vlUploadUsername
      } = data

      const thermalData = {
        hasData: tiHasData,
        type: 'THERMAL_IMAGE',
        typeName: '热成像',
        phone: tiUploadPhone,
        uplodaTime: tiUploadTime,
        uploadUsername: tiUploadUsername
      }
      const OpticsData = {
        hasData: vlHasData,
        type: ' VISIBLE_LIGHT',
        typeName: '可见光',
        phone: vlUploadPhone,
        uplodaTime: vlUploadTime,
        uploadUsername: vlUploadUsername
      }

      const result = []
      if (thermalImage) {
        result.push(thermalData)
      }
      if (visibleLight) {
        result.push(OpticsData)
      }

      return result
    }
  },
  methods: {
    handleUpload(row) {
      const $dialog = this.$refs.uploadDataDialog
      $dialog.currentLineData = { ...row }
      $dialog.show = true
    },
    handleUploadSucess() {
      this.fetchData()
      bus.$emit('updateTree')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
