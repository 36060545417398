<!--
 * @descriptionription: 编辑
-->
<template>
  <a-modal
    v-model="show"
    class="form_wrapper"
    width="700px"
    :title="title"
    @ok="handleConfirm"
    :confirmLoading="loading"
  >
    <a-form-model :model="form" :rules="rules" ref="form" v-bind="formLayout">
      <a-form-model-item prop="name" label="楼栋名称">
        <a-input placeholder="请输入楼栋名称" v-model="form.name" :maxLength="10"></a-input>
      </a-form-model-item>
    </a-form-model>

    <div class="tip_wrapper" v-if="!isEdit">
      <span class="tip">{{ tip }}</span>
    </div>
  </a-modal>
</template>

<script>
import ApiController from '@/views/report/api/building'
import modalFormMixin from '@/mixins/modalFormMixin'

const FORM = () => ({
  name: '',
  districtCode: '',
  address: '',
  description: '',
  buildingCount: '',
  radarCount: ''
})

export default {
  mixins: [modalFormMixin],
  props: {
    tip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ApiController,
      form: FORM(),
      rules: {
        name: [{ required: true, message: '请输入楼栋名称', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 显示事件
    async handleShow(item) {
      this.show = true
      this.item = { ...item }
      this.form = { ...FORM(), ...item }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    getSubmitForm() {
      return {
        ...this.form,
        workOrderId: this.$store.state.report.currentNode.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tip_wrapper {
  text-align: right;
}
</style>
