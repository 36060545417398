var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: { allowClear: "", placeholder: "全部" },
      model: {
        value: _vm.realVal,
        callback: function ($$v) {
          _vm.realVal = $$v
        },
        expression: "realVal",
      },
    },
    [
      _c("a-select-option", { key: "true", attrs: { value: 1 } }, [
        _vm._v("需要"),
      ]),
      _c("a-select-option", { key: "false", attrs: { value: 0 } }, [
        _vm._v("不需要"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }