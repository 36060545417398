var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form_wrapper",
      attrs: { width: "700px", title: _vm.title, confirmLoading: _vm.loading },
      on: { ok: _vm.handleConfirm },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "number", label: "墙面序号" } },
            [
              _c("a-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "number",
                  placeholder: "请输入墙面序号",
                  min: 1,
                },
                model: {
                  value: _vm.form.number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "number", $$v)
                  },
                  expression: "form.number",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "visibleLight", label: "是否需要可见光" } },
            [
              _c("TrueAndFalseSelector", {
                model: {
                  value: _vm.form.visibleLight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "visibleLight", $$v)
                  },
                  expression: "form.visibleLight",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "thermalImage", label: "是否需要热成像拍照" } },
            [
              _c("TrueAndFalseSelector", {
                model: {
                  value: _vm.form.thermalImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "thermalImage", $$v)
                  },
                  expression: "form.thermalImage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }