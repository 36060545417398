/*
 * @Description: 检测机构相关接口
 */

import ApiController from './functionApi'
import ReportController from './report'
import BuildingController from './building'
import WallController from './wall'
import LineController from './line'
import PointController from './point'

export {
  ReportController,
  BuildingController,
  WallController,
  LineController,
  ApiController,
  PointController
}

export default ApiController
