/*
 * @Description: 文件描述
 */

const filterFormMixin = {
  data() {
    return {
      formLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
      },
    }
  },
  mounted() {},
  methods: {
    reset() {
      this.$refs.form.resetFields()
      this.$nextTick(() => {
        this.search()
      })
    },
    search() {
      this.$emit('search')
    }
  }
}

export {
  filterFormMixin
}

export default filterFormMixin
