/*
 * @Description: 雷达监测点控制器
 */

import { ReportControllerFac } from './_utils'

const BASE_URL = `/api/organization/orderDetail/point`
const ApiController = new ReportControllerFac(BASE_URL)

export default ApiController
