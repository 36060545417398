<!--
 * @Description: 列表
-->
<template>
  <a-card class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->

    <div class="mb-10 btn_group_wrapper" v-if="couldEdit">
      <a-button type="primary" @click="() => handleAdd()">新增楼栋</a-button>
    </div>

    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="楼栋名称" field="name"></vxe-column>
        <vxe-column title="创建时间" field="createTime"></vxe-column>
        <vxe-column title="操作" field="operate" align="right" v-if="couldEdit">
          <template #default="{ row }">
            <a-button type="link" @click="() => handleAddEditItem(row)">修改</a-button>
            <a-button type="link" @click="() => handleDeleteItem(row)">删除</a-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <AddEditItemDialog :tip="numberTip" ref="AddEditItemDialog" @successed="updateTableAndTree"></AddEditItemDialog>
  </a-card>
</template>

<script>
import ApiController from '@/views/report/api/building'
import { getBuildingMaxNumber } from '../../api/functionApi'
import { TableMixin } from '@/views/report/_mixin'
import AddEditItemDialog from './AddEditItemDialog'

export default {
  mixins: [TableMixin],
  components: {
    AddEditItemDialog
  },
  data() {
    return {
      ApiController,
      // 当前楼栋数量与最大限制
      maxLimit: undefined
    }
  },
  computed: {
    recordCount() {
      return this.tableData.length
    },
    // 楼栋数量提示
    numberTip() {
      return `楼栋数量${this.recordCount + 1}/${this.maxLimit || 0}`
    }
  },
  mounted() {
    this.fetchMaxLimit()
  },
  methods: {
    // 获取最大楼栋数量限制
    async fetchMaxLimit() {
      const params = { id: this.$route.params.id }
      const data = await getBuildingMaxNumber(params)
      this.maxLimit = data.max
    },
    // 添加楼栋
    handleAdd() {
      if (this.recordCount >= this.maxLimit) {
        this.$message.warning(`楼栋数量已达上限 ${this.maxLimit}，无法新增!`)
        return
      }

      this.handleAddEditItem() // mixin
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
