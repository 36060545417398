<!--
 * @descriptionription: 编辑
-->
<template>
  <a-modal
    v-model="show"
    class="form_wrapper"
    width="700px"
    :title="title"
    @ok="handleConfirm"
    :confirmLoading="loading"
  >
    <a-form-model :model="form" :rules="rules" ref="form" v-bind="formLayout">
      <a-form-model-item prop="number" label="墙面序号">
        <a-input
          type="number"
          style="width: 100%;"
          placeholder="请输入墙面序号"
          v-model="form.number"
          :min="1"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item prop="visibleLight" label="是否需要可见光">
        <TrueAndFalseSelector v-model="form.visibleLight"></TrueAndFalseSelector>
      </a-form-model-item>

      <a-form-model-item prop="thermalImage" label="是否需要热成像拍照">
        <TrueAndFalseSelector v-model="form.thermalImage"></TrueAndFalseSelector>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import ApiController from '@/views/report/api/wall'
import modalFormMixin from '@/mixins/modalFormMixin'
import TrueAndFalseSelector from '../components/TrueAndFalseSelector.vue'

const FORM = () => ({
  number: undefined,
  visibleLight: false,
  thermalImage: false
})

export default {
  mixins: [modalFormMixin],
  components: {
    TrueAndFalseSelector
  },
  data() {
    const validateMax = (rule, value, callback) => {
      if (isNaN(Number(value))) {
        callback(new Error('请输入合法序号'))
      } else if (Number(value) > 99999) {
        callback(new Error('超过最大序号99999'))
      } else {
        callback()
      }
    }

    return {
      ApiController,
      form: FORM(),
      rules: {
        number: [
          { required: true, message: '请输入墙面编号', trigger: 'blur' },
          { pattern: /^\d*$/, message: '请输入正确的墙面序号', trigger: 'change' },
          { validator: validateMax, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // // 显示事件
    async handleShow(item) {
      this.show = true
      this.item = { ...item }
      this.form = { ...FORM(), ...item }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    getSubmitForm() {
      return {
        ...this.form,
        buildingId: this.$store.state.report.currentNode.id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
