/*
 * @Description: 常规列表mixin
 */
import { getFilterParams } from '@/utils/utils.custom'
import { bus } from '@/views/report/_utils'

const TableMixin = {
  props: {
    getFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      ApiController: {},
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      currentRow: {}
    }
  },
  computed: {
    currentNode() {
      return this.$store.state.report.currentNode
    },
    couldEdit() {
      const couldEditStatusList = [ 'DETECTING', 'DETECTED', 'UPLOADED', 'AVAILABLE_REJECTED' ]
      return couldEditStatusList.includes(this.$route.query?.status)
    }
  },
  watch: {
    currentNode: {
      immediate: true,
      handler(newVal) {
        if (newVal?.id) {
          this.fetchData()
        }
      }
    }
  },
  methods: {
    getFetchParams() {
      return {
        ...getFilterParams.call(this),
        id: this.currentNode.id,
      }
    },
    // 拉取数据
    async fetchData() {
      const params = this.getFetchParams()

      try {
        this.loading = true
        const data = await this.ApiController.getList({ ...params })
        this.tableData = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 添加编辑后更新列表数据, 新增编辑都要刷新树
    updateTableAndTree() {
      this.fetchData()
      bus.$emit('updateTree')
    },
    // 编辑元素
    handleAddEditItem(row = {}) {
      this.currentRow = { ...row }
      this.$nextTick(() => {
        this.$refs.AddEditItemDialog.handleShow(row)
      })
    },
    // 删除元素
    handleDeleteItem(row) {
      const _deleteAction = async () => {
        try {
          await this.ApiController.deleteItem(row)
          this.$message.success('删除成功!')
          this.fetchData()
          bus.$emit('updateTree')
        } catch (error) {
          console.log(error)
        }
      }

      const title = (h) => {
        return (
          <div>
            <span>确定要删除么？</span>
            <b style="color: #C03639;">删除之后不可恢复!</b>
          </div>
        )
      }

      this.$confirm({
        type: 'warning',
        title: title,
        onOk: _deleteAction
      })
    }
  }
}

export {
  TableMixin,
}
