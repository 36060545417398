<!--
 * @Description: 列表
-->
<template>
  <a-card class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->

    <div class="mb-10 btn_group_wrapper">
      <a-button type="primary" v-if="couldEdit" @click="handleAddEditItem">新增测线</a-button>
    </div>

    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="测线类型" field="type" :formatter="['enum', LINE_TYPE_ENUM]"></vxe-column>
        <vxe-column title="测线序号" field="number"></vxe-column>
        <vxe-column title="距离墙头（米）" field="distance">
          <template #default="{ row }">
            <span>{{ row.distance / 100 || '-' }}</span>
          </template>
        </vxe-column>
        <vxe-column title="创建时间" field="createTime"></vxe-column>

        <vxe-column title="操作" field="operate" align="right" v-if="couldEdit">
          <template #default="{ row }">
            <a-button type="link" @click="() => handleAddEditItem(row)">修改</a-button>
            <a-button type="link" @click="() => handleDeleteItem(row)">删除</a-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <!-- 分页 -->
    <!-- <Pagination :pagination.sync="pagination" @change="fetchData" /> -->

    <AddEditItemDialog ref="AddEditItemDialog" @successed="updateTableAndTree"></AddEditItemDialog>
  </a-card>
</template>

<script>
import ApiController from '@/views/report/api/line'
import { TableMixin } from '@/views/report/_mixin'
import { LINE_TYPE_ENUM } from '@/views/report/_const'
import AddEditItemDialog from './AddEditItemDialog'

export default {
  mixins: [TableMixin],
  components: {
    AddEditItemDialog
  },
  data() {
    return {
      LINE_TYPE_ENUM,
      ApiController
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
