var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table_area_wrapper" },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [_c("a-tab-pane", { key: "1", attrs: { tab: "数据上传" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "table_wrapper" },
        [
          _c(
            "vxe-table",
            { attrs: { data: _vm.tableData, loading: _vm.loading } },
            [
              _c("vxe-column", {
                attrs: { title: "数据类型", field: "typeName" },
              }),
              _c("vxe-column", {
                attrs: { title: "检测时间", field: "checkTime" },
              }),
              _c("vxe-column", {
                attrs: { title: "检测人名称", field: "checkUsername" },
              }),
              _c("vxe-column", {
                attrs: { title: "检测人联系电话", field: "checkPhone" },
              }),
              _c("vxe-column", {
                attrs: { title: "上传时间", field: "uploadTime" },
              }),
              _c("vxe-column", {
                attrs: { title: "上传人名称", field: "uploadUsername" },
              }),
              _c("vxe-column", {
                attrs: { title: "上传人联系电话", field: "uploadPhone" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }