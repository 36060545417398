<!--
 * @Description: 雷达监测点数据列表
-->
<template>
  <div class="table_area_wrapper">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="数据上传"></a-tab-pane>
    </a-tabs>

    <!-- class="main_table custom_vxe_table_wrapper" + height=auto 可以自动高度 -->
    <div class="table_wrapper">
      <vxe-table :data="tableData" :loading="loading">
        <vxe-column title="数据类型" field="typeName"></vxe-column>
        <vxe-column title="检测时间" field="checkTime"></vxe-column>
        <vxe-column title="检测人名称" field="checkUsername"></vxe-column>
        <vxe-column title="检测人联系电话" field="checkPhone"></vxe-column>
        <vxe-column title="上传时间" field="uploadTime"></vxe-column>
        <vxe-column title="上传人名称" field="uploadUsername"></vxe-column>
        <vxe-column title="上传人联系电话" field="uploadPhone"></vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import uploadDataController from '@/views/report/api/uploadData'
import { uploadDataMixin } from '@/views/report/_mixin'

const ApiController = {
  getList: uploadDataController.pointUploadData
}

export default {
  mixins: [uploadDataMixin],
  data() {
    return {
      ApiController,
      loading: false,
      tableData: []
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
