<!--
 * @Description: 文件描述
-->
<template>
  <a-modal v-model="show" title="上传数据" :confirmLoading="loading" @ok="handleUploadWallData">
    <a-form-model inline :labelCol="{ span: 6 }" :wrapperCol="{ span: 16 }">
      <a-form-model-item label="数据上传">
        <SingleFileUploader
          ref="SingleFileUploader"
          :before-upload="beforeUpload"
          accept="application/x-zip-compressed"
          v-model="url"
        ></SingleFileUploader>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import SingleFileUploader from '@/components/SingleFileUploader/index.vue'
import ApiController from '@/views/report/api/uploadData'

export default {
  components: {
    SingleFileUploader
  },
  data() {
    return {
      currentLineData: {}, // 当前的墙面上传数据行
      show: false,
      loading: false,
      url: ''
    }
  },
  watch: {
    show(newVal) {
      if (!newVal) {
        this.url = ''
      }
    }
  },
  methods: {
    beforeUpload(file) {
      if (file.type !== 'application/x-zip-compressed') {
        this.$warning({ title: '选择失败', content: '请选择zip格式的文件' })
        this.$nextTick(() => {
          this.$refs.SingleFileUploader.fileList = []
        })
        return false
      }
    },
    // 上传墙面数据
    async handleUploadWallData() {
      const params = {
        id: this.$store.state.report.currentNode.id,
        type: this.currentLineData.type,
        url: this.url
      }

      if (!this.url) {
        this.$message.warning('未上传任何数据!')
        return
      }

      try {
        this.loading = true
        await ApiController.uploadWallData(params)
        this.$message.success('上传成功!')
        this.$emit('success')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
