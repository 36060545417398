<!--
 * @Description: 可见光，热成像 true 和 false 选择
-->
<template>
  <a-select allowClear v-model="realVal" placeholder="全部">
    <a-select-option key="true" :value="1">需要</a-select-option>
    <a-select-option key="false" :value="0">不需要</a-select-option>
  </a-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return {}
  },
  computed: {
    realVal: {
      get() {
        // eslint-disable-next-line
        return this.value == undefined || this.value === '' ? undefined : Number(this.value)
      },
      set(newVal) {
        const _newVal = newVal === undefined ? undefined : Boolean(newVal)
        this.$emit('input', _newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
