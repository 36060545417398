/*
 * @Description: 报告控制器
 */

import ControllerFac from '@/api/utils/ControllerFac'

const BASE_URL = `/api/organization/workOrder`
const ApiController = new ControllerFac(BASE_URL)

export default ApiController
