<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="number" label="墙面序号">
          <a-input v-model="form.number" placeholder="请输入墙面序号"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>

        <a-form-model-item prop="visibleLight" label="可见光拍照">
          <TrueAndFalseSelector v-model="form.visibleLight"></TrueAndFalseSelector>
        </a-form-model-item>

        <a-form-model-item prop="thermalImage" label="热成像拍照">
          <TrueAndFalseSelector v-model="form.thermalImage"></TrueAndFalseSelector>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import { filterFormMixin } from '@/mixins/filterFormMixin'
import TrueAndFalseSelector from '../components/TrueAndFalseSelector.vue'

const FORM = () => ({
  number: '',
  startAndEnd: [undefined, undefined],
  visibleLight: undefined,
  thermalImage: undefined
})

export default {
  mixins: [filterFormMixin],
  components: {
    TrueAndFalseSelector
  },
  data() {
    return {
      form: FORM()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
