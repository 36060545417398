<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="type" label="测线类型">
          <EnumSelector v-model="form.type" :enumConfig="LINE_TYPE_ENUM"></EnumSelector>
        </a-form-model-item>

        <a-form-model-item prop="number" label="测线序号">
          <a-input v-model="form.number" placeholder="请输入测线序号"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import { filterFormMixin } from '@/mixins/filterFormMixin'
import { LINE_TYPE_ENUM } from '@/views/report/_const'

const FORM = () => ({
  type: undefined,
  number: '',
  startAndEnd: [undefined, undefined]
})

export default {
  mixins: [filterFormMixin],
  data() {
    return {
      LINE_TYPE_ENUM,
      form: FORM()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
