var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "上传数据", confirmLoading: _vm.loading },
      on: { ok: _vm.handleUploadWallData },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            inline: "",
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "数据上传" } },
            [
              _c("SingleFileUploader", {
                ref: "SingleFileUploader",
                attrs: {
                  "before-upload": _vm.beforeUpload,
                  accept: "application/x-zip-compressed",
                },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }