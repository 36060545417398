<!--
 * @Description: 墙面列表
-->
<template>
  <div class="wall_list_wrapper">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="墙面列表"></a-tab-pane>
    </a-tabs>
    <FilterForm ref="FilterForm" @search="updateTable"></FilterForm>
    <div class="mt-10"></div>
    <MainTable ref="MainTable" class="main_table" :getFilter="getFilter"></MainTable>
  </div>
</template>

<script>
import FilterForm from './FilterForm.vue'
import MainTable from './MainTable.vue'

export default {
  name: '',
  components: {
    FilterForm,
    MainTable
  },
  data() {
    return {}
  },
  methods: {
    getFilter() {
      const form = this.$refs.FilterForm.form
      const { visibleLight, thermalImage } = form
      return {
        ...form,
        // eslint-disable-next-line
        visibleLight: visibleLight == undefined ? undefined : Boolean(visibleLight),
        // eslint-disable-next-line
        thermalImage: thermalImage == undefined ? undefined : Boolean(thermalImage)
      }
    },
    updateTable() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
