/*
 * @Description: 文件描述
 */

const LINE_TYPE_ENUM = {
  RADAR: '雷达测线',
  KNOCK: '敲击测线',
  values: ['RADAR', 'KNOCK']
}

const DATA_TYPE_ENUM = {
  knockLine: '敲击数据',
  radarPoint: '雷达数据',
  thermalImage: '热成像图像',
  visibleLight: '可见光图像'
}

export {
  LINE_TYPE_ENUM,
  DATA_TYPE_ENUM
}
