var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    _vm._b(
      {
        attrs: {
          name: "file",
          withCredentials: "",
          action: "/foundation/api/file",
          "file-list": _vm.fileList,
        },
        on: { change: _vm.handleChange },
      },
      "a-upload",
      _vm.$attrs,
      false
    ),
    [_c("a-button", { attrs: { type: "primary" } }, [_vm._v("选择文件")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }