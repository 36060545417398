<!--
 * @Description: 数据管理
-->
<template>
  <page-header-wrapper>
    <a-card class="report_data_manage_wrapper">
      <div class="main_content">
        <div class="left_wrapper">
          <TreeArea></TreeArea>
        </div>
        <div class="right_wrapper">
          <component class="component_wrapper" :is="currentListComp"></component>
        </div>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import TreeArea from './components/TreeArea.vue'
import BuildingList from './BuildingList'
import WallList from './WallList'
import LineList from './LineList.vue'
import RadarPointList from './RadarPointList'
import KnockDataList from './KnockDataList'
import RadarDataList from './RadarDataList'

const ListCompEnum = {
  PROJECT: BuildingList, // 项目下对应楼栋列表
  BUILDING: WallList, // 楼栋下对应墙面列表
  WALL: LineList, // 墙面下对应测线列表，雷达测线和敲击测线
  // 雷达测线分支
  RADAR_LINE: RadarPointList, // 雷达测线下有检测点
  POINT: RadarDataList, // 检测点对应雷达检测点数据
  // 敲击测线下直接就是敲击数据，无检测点
  KNOCK_LINE: KnockDataList // 敲击测线下是敲击数据
}

export default {
  name: 'ReportDataManage',
  components: {
    TreeArea
  },
  data() {
    return {
      projectId: ''
    }
  },
  computed: {
    currentNode() {
      return this.$store.state.report.currentNode
    },
    currentListComp() {
      return ListCompEnum[this.currentNode.type] || BuildingList
    }
  },
  created() {
    this.projectId = Number(this.$route.params.id)
  }
}
</script>

<style lang="scss" scoped>
.report_data_manage_wrapper {
  height: calc(100vh - 185px);
  ::v-deep .ant-card-body {
    height: 100%;
  }
}

.main_content {
  display: flex;
  height: 100%;

  .left_wrapper {
    width: 280px;
    height: 100%;
    border: 1px solid #eee;
    overflow: auto;
  }

  .right_wrapper {
    flex: 1;
    overflow-x: hidden;
    margin-left: 4px;
    padding: 10px;
    border: 1px solid #eee;
  }
}
</style>
<style lang="scss">
.report_data_manage_wrapper {
  .table_area_wrapper {
    .custom_vxe_table_wrapper {
      flex: 1;
    }
  }

  .btn_group_wrapper {
    text-align: right;
  }

  .component_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .main_table {
      flex: 1;
      .ant-card-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
