/*
 * @Description: 上传数据的接口
 */

import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/organization/orderDetail`

// 墙面上传数据_查看
function wallUploadData(params) {
  return request({
    url: `${BASE_URL}/wall/upload/${params.id}`,
    method: 'get',
  })
}

// 测线上传数据_查看
function lineUploadData(params) {
  return request({
    url: `${BASE_URL}/line/upload/${params.id}`,
    method: 'get',
  })
}

// 检测点上传数据_查看
function pointUploadData(params) {
  return request({
    url: `${BASE_URL}/point/upload/${params.id}`,
    method: 'get',
  })
}

// 墙面上传数据_上传
function uploadWallData(params) {
  return request({
    url: `${BASE_URL}/wall/upload/${params.id}`,
    method: 'post',
    data: params
  })
}

export {
  wallUploadData,
  lineUploadData,
  pointUploadData
}

export default { wallUploadData, lineUploadData, pointUploadData, uploadWallData }
