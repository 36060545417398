var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "table_area_wrapper" },
    [
      _c(
        "div",
        { staticClass: "mb-10 btn_group_wrapper" },
        [
          _vm.couldEdit
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      return _vm.handleAddEditItem()
                    },
                  },
                },
                [_vm._v("新增墙面")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "custom_vxe_table_wrapper" },
        [
          _c(
            "vxe-table",
            {
              attrs: {
                height: "auto",
                data: _vm.tableData,
                loading: _vm.loading,
              },
            },
            [
              _c("vxe-column", {
                attrs: { title: "墙面序号", field: "number" },
              }),
              _c("vxe-column", {
                attrs: { title: "创建时间", field: "createTime" },
              }),
              _c("vxe-column", {
                attrs: { title: "可见光拍照", field: "visibleLight" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.visibleLight ? "需要" : "不需要")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-column", {
                attrs: { title: "热成像拍照", field: "thermalImage" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.thermalImage ? "需要" : "不需要")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.couldEdit
                ? _c("vxe-column", {
                    attrs: { title: "操作", field: "operate", align: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleAddEditItem(row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleDeleteItem(row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1536330437
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("AddEditItemDialog", {
        ref: "AddEditItemDialog",
        on: { successed: _vm.updateTableAndTree },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }