var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table_wrapper" },
    [
      _c(
        "vxe-table",
        { attrs: { data: _vm.realTableData, loading: _vm.loading } },
        [
          _c("vxe-column", { attrs: { title: "数据类型", field: "typeName" } }),
          _c("vxe-column", {
            attrs: { title: "上传时间", field: "uplodaTime" },
          }),
          _c("vxe-column", { attrs: { title: "上传人", field: "uplodaTime" } }),
          _c("vxe-column", {
            attrs: { title: "上传人联系电话", field: "uplodaTime" },
          }),
          _vm.couldEdit
            ? _c("vxe-column", {
                attrs: { title: "操作", field: "operate", align: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          !row.hasData
                            ? _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleUpload(row)
                                    },
                                  },
                                },
                                [_vm._v("上传数据")]
                              )
                            : _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleUpload(row)
                                    },
                                  },
                                },
                                [_vm._v("重新上传")]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  232165750
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("uploadDataDialog", {
        ref: "uploadDataDialog",
        on: { success: _vm.handleUploadSucess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }