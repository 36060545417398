/*
 * @Description: 墙面控制器
 */

import { ReportControllerFac } from './_utils'

const BASE_URL = `/api/organization/orderDetail/wall`
const ApiController = new ReportControllerFac(BASE_URL)

export default ApiController
