<!--
 * @Description: 雷达监测点列表
-->
<template>
  <div class="radar_point_list_wrapper">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="检测点列表"></a-tab-pane>
    </a-tabs>

    <FilterForm ref="FilterForm" @search="updateTable"></FilterForm>
    <div class="mt-10"></div>
    <MainTable ref="MainTable" class="main_table" :getFilter="getFilter"></MainTable>
  </div>
</template>

<script>
import FilterForm from './FilterForm.vue'
import MainTable from './MainTable.vue'

export default {
  name: '',
  components: {
    FilterForm,
    MainTable
  },
  data() {
    return {}
  },
  methods: {
    getFilter() {
      return this.$refs.FilterForm.form
    },
    updateTable() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
