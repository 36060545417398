var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form_wrapper",
      attrs: { width: "700px", title: _vm.title, confirmLoading: _vm.loading },
      on: { ok: _vm.handleConfirm },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "name", label: "楼栋名称" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入楼栋名称", maxLength: 10 },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isEdit
        ? _c("div", { staticClass: "tip_wrapper" }, [
            _c("span", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }