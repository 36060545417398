<!--
 * @Description: 左侧树结构
-->
<template>
  <a-tree
    show-line
    :expanded-keys="expandedKeys"
    :auto-expand-parent="autoExpandParent"
    :selected-keys="selectedKeys"
    :tree-data="treeData"
    @expand="onExpand"
    @select="onSelect"
  >
    <template #title="{ title, red }">
      <a-badge :dot="red">
        <span>{{ title }}</span>
      </a-badge>
    </template>
  </a-tree>
</template>
<script>
import { flatternData, bus } from '@/views/report/_utils'
import ApiController from '@/views/report/api'
import { jsonToTree } from '@/utils/arrayUtil'
import _ from 'lodash'

export default {
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      flattenTreeData: [],
      treeData: []
    }
  },
  computed: {
    currentNode: {
      get() {
        return this.$store.state.report.currentNode
      },
      set(newVal) {
        this.$store.commit('SET_CURRENT_NODE', newVal)
      }
    }
  },
  mounted() {
    this.initTree()
    bus.$on('updateTree', this.fetchTreeData)
  },
  beforeDestroy() {
    bus.$off('updateTree')
  },
  methods: {
    async fetchTreeData() {
      const projectId = this.$route.params.id
      const params = { id: projectId }
      try {
        this.loading = true
        const res = (await ApiController.orderDetailTree(params)) || {}
        res.type = 'PROJECT' // 给第一层写死一个
        this.processData(res)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async initTree() {
      await this.fetchTreeData()
      await this.$nextTick()
      this.setDefaultStatus()
    },
    // 处理数据
    processData(res) {
      const _listData = flatternData([res])
      const _clonedData = _.cloneDeep(_listData)

      this.flattenTreeData = _listData // 保存一份一维节点的副本,方便以后操作
      this.treeData = jsonToTree({
        list: _clonedData,
        fields: { idKey: 'key', pidKey: 'parentKey' }
      })
    },
    // 设置默认状态
    setDefaultStatus() {
      const _firstNode = this.treeData[0]
      this.selectedKeys = [_firstNode.key] // 设置默认选中
      this.expandedKeys = [_firstNode.key] // 设置默认展开
      this.currentNode = _firstNode // 设置默认选中节点
    },
    // 展开节点本例中需要受控
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onSelect(selectedKeys, info) {
      if (info.selected === false) return
      const currentNodeData = this.flattenTreeData.find((item) => item.key === selectedKeys[0]) || {}
      this.currentNode = currentNodeData
      this.selectedKeys = selectedKeys
    }
  }
}
</script>
